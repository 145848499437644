<template>
  <div id="container2" style="border-radius: 0.3rem;">
    <h5>
      <v-icon color="black" size="15" class="ma-2" right>{{ icon }}</v-icon>
      Details
    </h5>
  </div>
</template>

<script>
export default {
  name: "DashboardLongButtons",
  props: ["icon"]
};
</script>

<style scoped>
#container2 {
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: center;
  align-items: center;
  alignt-content: stretch;
  display: flex;
  border: 1px solid #34495e;
  width: 200px;
  height: 40px;
  margin-left: 20px;
  border-radius: 30px;
  padding: 10px;
}
</style>
